import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { AddressBody, AddressResponse, Agent } from "@src/lib/interface";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const updateAddress = async (address: AddressBody): Promise<AddressResponse | undefined> => {
  try {
    const { body } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/address`)
      .set(await getAuthHeader())
      .send(address);
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};
const updateDistancePreference = async (distance: number): Promise<Agent> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: "distance", value: distance })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { updateAddress, updateDistancePreference };
