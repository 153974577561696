import { InputChangeEventDetail } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import { logEvent } from "@src/appV2/lib/analytics";
import { getAddressByGeoCode } from "@src/lib/deprecatedCode";
import { Location } from "@src/lib/interface";
import { pin } from "ionicons/icons";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AddressEditMap } from "./addressEditMap";
import { updateAddress } from "./api";
import { USER_EVENTS } from "../../constants/userEvents";
import { setAddressEditDetails, updateAddressLine1 } from "../store/accountSettings";
import { updateAgent } from "../store/session";
import { Store } from "../store/store.model";

const AddressEdit: FC = () => {
  const [locating, setLocating] = useState<boolean>(false);
  const { addressEditDetails } = useSelector((state: Store) => state.accountSettingsStore);
  const dispatch = useDispatch();
  const history = useHistory();

  const updateMapAddress = async (markerLocation: Location): Promise<void> => {
    const geocodeAddress = await getAddressByGeoCode(markerLocation);
    const newAddress = {
      ...addressEditDetails?.address,
      ...geocodeAddress,
      line1: addressEditDetails?.addressLine1,
      subpremise: "",
    };
    dispatch(
      setAddressEditDetails({
        address: newAddress,
        location: markerLocation,
        searchLocation: newAddress.formatted,
        addressLine1: addressEditDetails?.addressLine1,
      })
    );

    setLocating(false);
  };

  const onLocationChange = (markerLocation: Location): void => {
    setLocating(true);
    updateMapAddress(markerLocation);
  };

  const onAddressLineOneChange = ({ detail }: CustomEvent<InputChangeEventDetail>) => {
    dispatch(updateAddressLine1(detail.value as string));
  };

  const onUpdateAddress = async () => {
    const updatedAddress = await updateAddress({
      address: {
        ...addressEditDetails?.address,
        line1: addressEditDetails?.addressLine1 ?? "",
        subpremise: addressEditDetails?.address?.subpremise ?? "",
      },
      location: addressEditDetails?.location as Location,
    });

    if (!updatedAddress) {
      return;
    }

    const { geoLocation, address, status } = updatedAddress;

    if (status === 200) {
      logEvent(USER_EVENTS.UPDATED_ADDRESS);
    }
    dispatch(updateAgent({ geoLocation, address }));
    history.go(-2);
  };

  const onEditSearchQuery = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent>
        <AddressEditMap location={addressEditDetails?.location} onChange={onLocationChange} />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonItem>
          <IonLabel position="stacked" color="primary">
            <IonIcon icon={pin} /> {locating ? "Locating..." : "Address"}
          </IonLabel>
          <IonInput readonly={true} value={addressEditDetails?.searchLocation} />
          <IonButton size="small" slot="end" fill="outline" onClick={onEditSearchQuery}>
            Change
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Apartment/Floor/Building (if applicable)</IonLabel>
          <IonInput
            debounce={1000}
            // disable if the user has already picked a subpremise using autocomplete
            disabled={addressEditDetails?.address?.subpremise ? true : false}
            clearInput={true}
            placeholder="Apartment, e.g. “Unit 2”"
            autofocus={true}
            value={addressEditDetails?.address?.subpremise || addressEditDetails?.addressLine1}
            onIonChange={onAddressLineOneChange}
          />
        </IonItem>
        <IonButton strong={true} expand="full" onClick={onUpdateAddress}>
          Update Address
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export { AddressEdit };
