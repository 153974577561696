import { IonBackButton, IonButton, IonButtons, IonIcon } from "@ionic/react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { getDeviceGeoLocation } from "@src/appV2/Location";
import { LA_CENTER } from "@src/lib/deprecatedCode";
import { locate } from "ionicons/icons";
import { isEqual } from "lodash";
import { Fragment, memo, useEffect, useState } from "react";

import { useMapControl } from "./useMapControl";
import { LatLng, googleMapContainerStyle } from "../openShifts/map/googleMapShifts.const";

const AddressEditMapComponent = ({ location, onChange }) => {
  const [mapCenter, setMapCenter] = useState(LA_CENTER);
  const [markerLocation, setMarkerLocation] = useState<LatLng>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [position, setPosition] = useState<google.maps.ControlPosition.RIGHT_BOTTOM | null>(null);

  const onCurrentLocationControl = async () => {
    const { geoLocation } = await getDeviceGeoLocation();
    const lat = geoLocation.latitude ?? "";
    const lng = geoLocation.longitude ?? "";
    onChange({ lat, lng });
  };

  useMapControl(
    map,
    position,
    <IonButton fill="clear" onClick={onCurrentLocationControl}>
      <IonIcon style={{ fontSize: "30px" }} icon={locate} mode="md" />
    </IonButton>
  );

  useEffect(() => {
    setMarkerLocation(location);
    setMapCenter(location || LA_CENTER);
  }, [location]);

  const onDrag = () => {
    setMarkerLocation({
      lat: map?.getCenter()?.lat() as number,
      lng: map?.getCenter()?.lng() as number,
    });
  };

  const onMapChange = () => {
    if (isEqual(location, markerLocation)) {
      return;
    }

    onChange({ ...markerLocation });
  };

  const handleOnGoogleApiLoaded = (mountedMap) => {
    setPosition(google.maps.ControlPosition.RIGHT_BOTTOM);
    setMap(mountedMap);
  };

  return (
    <Fragment>
      <div style={{ position: "absolute", left: "10px", top: "10px", zIndex: 999 }}>
        <IonButtons slot="start">
          <IonBackButton
            text=""
            color="dark"
            defaultHref="/home/account/searchAddressLocation"
            mode="ios"
          />
        </IonButtons>
      </div>

      <GoogleMap
        mapContainerStyle={googleMapContainerStyle}
        center={mapCenter}
        zoom={17}
        onBoundsChanged={onMapChange}
        options={{
          disableDefaultUI: true,
        }}
        onDrag={onDrag}
        onLoad={handleOnGoogleApiLoaded}
      >
        {markerLocation && <Marker position={markerLocation} />}
      </GoogleMap>
    </Fragment>
  );
};

export const AddressEditMap = memo(AddressEditMapComponent);
